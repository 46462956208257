module.exports = `
query get($ids: [ID], $langId: ID) {
  get(ids: $ids, langId: $langId) {
  	id
    title
    uri
    VIN
    owner{
      id,
      name
    }
    category{
      id
    }
    photos{
      main {
        id
      }
    }
    brand {
      id
      name
      eng
    }
    model {
      id
      name
      eng
      category {
        id
      }
    }
    body {
      id
    }
    location {
      city {
        id
        name
      }
      state {
        id
      }
    }
    gearbox {
      name
    }
    race
    engine {
      volume {
        liters
      }
    }
    fuel {
      name
    }
    publication {
      expiredAt
    }
    year
    price{
      all {
        USD {
          value
        }
        UAH {
          value
        }
      }
      main {
        value
        currency {
          id
        }
      }
    }
    levels{
      active {
        value
        createdAt
        expiredAt
      }
    }
  }
}
`;
