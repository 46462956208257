function listBuilder(data) {
	return `<ul class="unstyle">${listItemBuilder(data.elements)}</ul>`;
}

function listItemBuilder(data) {
	return data.map((item) => {
		switch (item.style) {
			case 'grey':
				return `<li class="item grey">${recursiveTemplating(item.elements)}</li>`;
			default:
				return `<li class="item">${recursiveTemplating(item.elements)}</li>`;
		}
	}).join('');
}

function textBuilder(templateData) {
	let _class = `${templateData.bold ? 'bold ' : ''}${templateData.style ? [templateData.style, ' '].join('') : ''}${templateData.size ? ['size', templateData.size, ' '].join('') : ''}`;
	return `<span class="${_class}">${templateData.content} </span>`;
}

function counterBuilder(templateData) {
	switch (templateData.style) {
		case 'blue':
			return `<span class="count _blue">${templateData.content}</span>`;
		case 'red':
			return `<span class="count _red">${templateData.content}</span>`;
		default:
			return `<span class="count">${templateData.content}</span>`;
	}
}

function linkBuilder(templateData) {
	let _class = `${templateData.bold ? 'bold ' : ''}${templateData.size ? ['size', templateData.size, ' '].join('') : ''}`;
	return `<a class="${_class}" target="${templateData.target}" href="${templateData.url}">${templateData.content}</a>`;
}

function recursiveTemplating(data) {
	switch (data.type) {
		case 'text_template':
			return data.elements.map((templateData) => {
				return recursiveTemplating(templateData);
			}).join('');
		case 'text':
			return textBuilder(data);
		case 'top_badge':
			return `<span class="promote-level">${data.content}</span>`;
		case 'icon':
			return `<i class="item-title-checked" style="background-color: ${data.colorHEX}"></i>`;
		case 'delimiter':
			return '<span class="point">•</span>';
		case 'new_line':
			return '<br/>';
		case 'list':
			return listBuilder(data);
		case 'list_item':
			return listItemBuilder(data);
		case 'counter':
			return counterBuilder(data);
		case 'link':
			return linkBuilder(data);
	}
}

module.exports = {
	methods: {
		_templating(data) {
			return data && recursiveTemplating(data);
		}
	}
};
