const { _levelsOpenStat } = require('../../../store/_laxios');

export default {
	props: ['data'],
	mixins: [require('../../../mixins/templateEngine')],
	methods: {
		loggerAddVinButton(click) {
			const href = click?.target?.href || '';
			if (href.includes('addauto/autoinfo/autoId') && href.includes('#VIN-block')) {
				const params = {
					auto_id: this.$route.params.autoId,
					src: 'pub_info_add_vin'
				};
				_levelsOpenStat.request({params}).catch(console.error);
			}
		},
	}
};
