import {mapGetters} from 'vuex';

module.exports = {
	computed: {
		...mapGetters({
			userData: 'Common/userData',
			langId: 'lang/id',
		})
	},
	methods: {
		sendEventToSlonik(eventId, payload = {}) {
			try {
				this._slonik({
					event_id: eventId,
					screentype: this.isDesktop ? 1 : (this.isMobile ? 2 : 3),
					lang_id: this.langId,
					current_url: window.location.pathname,
					previous_url: document.referrer,
					owner_id: this.userData.userId,
					form_type: 1,
					billing_service_id: payload.serviceId,
					proposal_id: this.$route.params.autoId,
					proposal_action_type: payload.actionType,
					project_id: 1,
					level_top: payload.level,
					level_period: payload.period,
					payment_type_id: payload.paymentType,
					billing_order_id: payload.billingId,
					action_block: payload.actionBlock,
					current_point_action: payload.pointAction,
					payment_sum: payload.paymentSum ? payload.paymentSum * 100 : undefined,
				});
			} catch (e) {
			}
		}
	},
};
