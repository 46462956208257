import { mapActions } from 'vuex';
import remove from 'lodash/remove';

export default {
  props: ['data', 'name', 'blockEasyPeriod'],
  mixins: [require('../../../../mixins/templateEngine')],
  data() {
    return {
      active: false,
      selectedValue: undefined,
      customValue: undefined,
      value: undefined,
      customPosition: undefined,
      sendRequest: false,
      tm: undefined,
      isSafari: false,
      showInfoTooltip: false,
    };
  },
  methods: {
    ...mapActions({
      fetchData: 'publication/fetchData',
    }),
    toggleSelect(isReload) {
      if (this.name === 'easy' && this.type === 'period' && this.blockEasyPeriod) {
        return;
      }

      if (!isReload) {
        this.$emit('passEventToSlonik', 697, { pointAction: (this.active ? 'close_' : 'open_') + this.type, actionBlock: 16 });
      }
      this.active = !this.active;
    },
    decrementCustomValue() {
      this.customValue--;
      this.$emit('passEventToSlonik', 697, { pointAction: `decrease_${this.type}`, actionBlock: 16 });
    },
    incrementCustomValue() {
      this.customValue++;
      this.$emit('passEventToSlonik', 697, { pointAction: `increase_${this.type}`, actionBlock: 16 });
    },
    getPosition() {
      this.fetchData({
        advertisementId: this.$route.params.autoId,
        queryType: 'position',
        queryParams: { level: this.customValue },
      }).then((data) => {
        this.customPosition = data;
        this.sendRequest = true;
      });
    },
    reloadBlock(params) {
      if (this.name === 'easy' && this.type === 'level') {
        this.isBlockEasyPeriod(!params.value);
      }
      this.value = params.value;
      this.$root.$emit('reloadBlock', { blockType: this.name, type: this.type, value: params.value });
      // перемальовуємо інші блоки, щоб там теж був такий же період публікації https://auto-ria.atlassian.net/browse/VCWEB-4568
      if (this.type === 'periodPublication') {
        const blockNames = ['fast', 'turbo', 'easy'];
        remove(blockNames, (n) => n === this.name);
        blockNames.forEach((name) => {
          this.$root.$emit('reloadBlock', { blockType: name, type: this.type, value: params.value });
        });
      }
      this.$emit('passEventToSlonik', 697, { pointAction: params.custom ? `setCustom_${this.type}_${params.value}` : `select_${this.type}_${params.value}`, actionBlock: 16 });
      this.toggleSelect(true);
    },
    getOS() {
      if (navigator && navigator.userAgent.match(/iPhone|iPad|iPod|Mac/i) && navigator.userAgent.indexOf('Safari') > -1) {
        return this.isSafari = true;
      }
    },
    isBlockEasyPeriod(value) {
      this.$emit('isBlockEasyPeriod', value);
    },
    onClickCloseTooltip() {
      this.showInfoTooltip = false;
      localStorage.setItem('tooltipPeriodPublication', 1);
    },
  },
  computed: {
    selectedItem() {
      return this?.data?.elements && this.data.elements.find((i) => i.selected) || {};
    },
    type() {
      switch (this.data.style) {
        case 'select-list':
          return 'level';
        case 'select-input':
          return 'period';
        case 'publication-period':
          return 'periodPublication';
      }
    },
  },
  mounted() {
    this.getOS();
    this.value = this.data.selectedValue;
    this.customValue = this.data.selectedValue;
    this.showInfoTooltip = !localStorage.getItem('tooltipPeriodPublication');
  },
  watch: {
    customValue() {
      this.sendRequest = false;
      clearTimeout(this.tm);
      if (this.customValue < 1) {
        this.customValue = this.name === 'easy' ? 0 : 1;
      }
      if (this.customValue > 5000) {
        this.customValue = 5000;
      }

      if (this.type === 'level') {
        this.tm = setTimeout(() => {
          this.getPosition();
        }, 1000);
      }
    },
    data() {
      this.value = this.data.selectedValue;
      this.customValue = this.data.selectedValue;
    },
  },
  i18n: {
    messages: {
      uk: {
        'Оберіть рівень ТОП': 'Оберіть рівень ТОП',
        'Оберіть період розміщення': 'Оберіть період розміщення',
        'Оберіть ваш варіант ТОП': 'Оберіть ваш варіант ТОП',
        'Вкажіть кількість днів ТОП': 'Вкажіть кількість днів ТОП',
        'Період публікації': 'Період публікації',
        'Вам доступний вибір': 'Вам доступний вибір періоду публікації',
        '': '',
      },
      ru: {
        'Оберіть рівень ТОП': 'Выберите уровень ТОП',
        'Оберіть період розміщення': 'Выберите период размещения',
        'Оберіть ваш варіант ТОП': 'Выберите ваш вариант ТОП',
        'Вкажіть кількість днів ТОП': 'Укажите количество дней ТОП',
        'Період публікації': 'Период публикации',
        'Вам доступний вибір': 'Вам доступен выбор периода публикации',
        '': '',
      },
    },
  },
};
