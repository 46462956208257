import Popup from '../../../Common/Popup.vue';

export default {
  name: 'WasRecentlyPublished',
  components: {
    Popup,
  },
  i18n: {
    messages: {
      ru: {
        'Ви щойно використали публікацію': 'Вы только что использовали публикацию для этого авто и скоро оно появится в поиске',
        'Впевнені що хочете перепублікувати': 'Уверены, что хотите его опубликовать еще раз?',
        'Так переопублікувати': 'Да, переопубликовать',
      },
      uk: {
        'Ви щойно використали публікацію': 'Ви щойно використали публікацію для цього авто й скоро воно з’явиться в пошуку',
        'Впевнені що хочете перепублікувати': 'Впевнені, що хочете перепублікувати його ще раз?',
        'Так переопублікувати': 'Так, переопублікувати',
      },
    },
  },
};
