import Vue from 'vue';
import _laxios from '../_laxios';
const {congratulations} = __INITIAL_STATE__;

module.exports = {
	namespaced: true,
	state: Object.assign({
		action: undefined,
		GAeventName: undefined,
		autoId: undefined,
		args: {},
		autoData: {},
		autotestData: {},
		abroadData: {},
		position: undefined,
		segment: '',
		typeStep: 0,
	}, congratulations),
	actions: {
		getCurrentPosition({commit, rootGetters}, {autoId, top, brandId, modelId, promiseVIN, category_id}) {
			let params = {
				marka_id: brandId,
				model_id: modelId,
				promiseVIN: promiseVIN,
				category_id: category_id
			};
			return _laxios.forecastPosition
				.expandUrl({autoId: autoId, top: top})
				.request({params})
				.catch((e) => {
					console.error('ERROR:::forecastPosition', e.message);
				});
		},
		/**
		 * Метод який ходить на graphQL за данними про авто
		 * @param commit - об'єкт з контексту
		 * @param getters - об'єкт з контексту
		 * @param args - об'єкт вхідних аргументів
		 * @param action - тип сторінки-привітання в залежності від дії яку робить юзер
		 * @param autoId - autoId
		 * @return {Promise.<T>|*}
		 */
		autoInfo({commit, getters, rootGetters}, {action, args, autoId}) {
			let {'shared/url/current': url, 'lang/id': langId} = rootGetters;
			let sendRequest = false; let ids = [];
			ids.push(autoId);

			// комітимо зразу тип сторінки, яку потрібно відобразити
			commit('action', {action});
			commit('GAeventName', {GAeventName: action === 'autoRenewed' ? 'Congratulations_Step_Edit' : 'Congratulations_Step_Publication'});
			commit('autoId', {autoId});
			commit('args', {args});

			if (_TARGET_ === 'client') {
				if (!Object.keys(getters.autoInfo).length) {
					sendRequest = true;
				}
			} else {
				sendRequest = true;
			}

			const data = {query: require('../_graphql/queries/advStepCongratulation'), variables: {ids, langId}};
			if (sendRequest) {
				return _laxios.graphql
					.request({data})
					.then(({data: {get: advertisements = []} = {}} = {}) => {
						commit('autoInfo', advertisements[0]);
					})
					.catch(e => {
						console.log('[GRAPH_QL API] method:(get) error:', e.message, {url});
					});
			}
		},
		/**
		 * Метод який ходить на бекенд автотесту для отримання інфи, яку перевірку ми можем запропонувати
		 * @param commit - об'єкт з контексту
		 * @param getters - об'єкт з контексту
		 * @param autoId - autoId
		 * @return {Promise.<T>|*}
		 */
		autotestInfo({commit, getters}, {autoId}) {
			let data = {
				params: {autoId}
			};
			let sendRequest = false;

			if (_TARGET_ === 'client') {
				if (!Object.keys(getters.autotestInfo).length) {
					sendRequest = true;
				}
			} else {
				sendRequest = true;
			}

			if (sendRequest) {
				return _laxios.autotestData
					.request(data)
					.then((payload) => {
						commit('autotestInfo', payload);
					})
					.catch(e => {
						console.log('[AUTO_TEST API] method:(/autotest/sto/is_set_by_auto_id?autoId=:id) error:',
							e.message);
					});
			}

		},
		/**
		 * Метод, який отримує данні чи авто за кордоном
		 * @todo можна буде переробити коли в graph_ql з*являться такі данні як abroad та companyData
		 * @param commit
		 * @param getters
		 * @param autoId
		 * @return {Promise.<T>|*}
		 */
		abroadData({commit, getters}, {autoId}) {
			let sendRequest = false;

			if (_TARGET_ === 'client') {
				if (!Object.keys(getters.abroadData).length) {
					sendRequest = true;
				}
			} else {
				sendRequest = true;
			}

			if (sendRequest) {
				return _laxios.searchBlockData
					.expandUrl({autoId: autoId})
					.request()
					.then((payload) => {
						commit('abroadData', payload);
					})
					.catch(e => {
						console.log(
							'[REST_AUTO_RIA_SEARCH API] method:(/demo/bu/searchPage/v3/view/auto/{autoId}) error:',
							e.message);
					});
			}
		},
		/**
		 * Метод, який отримує данні на якій позиції знаходиться авто
		 * @param commit
		 * @param getters
		 * @param autoId
		 * @param brandId
		 * @param modelId
		 * @param abroad
		 * @return {Promise.<T>|*}
		 */
		position({commit, getters}, {autoId, brandId, modelId, abroad}) {
			let data = {
				params: {
					marka: brandId,
					model: modelId
				}
			};
			let sendRequest = false;

			if (abroad) {
				data.params.searchType = 'carBooking';
			}

			if (_TARGET_ === 'client') {
				if (!getters.position) {
					sendRequest = true;
				}
			} else {
				sendRequest = true;
			}

			if (sendRequest) {
				return _laxios.position
					.expandUrl({autoId: autoId})
					.request(data)
					.then((payload) => {
						commit('position', payload);
					})
					.catch(e => {
						console.log('[REST_AUTO_RIA_SEARCH API] method:(/levels/position/:id) error:',
							e.message);
					});
			}

		},
		loadSegment({commit}) {
			return _laxios.segment.request()
				.then(payload => {
					commit('segment', payload);
				})
				.catch(e => {
					console.log('[ STEP congratulation ] method:(/cabinet/api/segment) error:',	e.message);
				});
		},
		setTypeStep({commit}, { typeStep }) {
			return commit('typeStep', { typeStep });
		}
	},
	mutations: {
		autoInfo(state, autoData) {
			for (let key in autoData) {
				if (autoData.hasOwnProperty(key)) {
					Vue.set(state.autoData, key, autoData[key]);
				}
			}
		},
		autotestInfo(state, autotestData) {
			Vue.set(state, 'autotestData', autotestData);
		},
		action(state, {action}) {
			Vue.set(state, 'action', action);
		},
		GAeventName(state, {GAeventName}) {
			Vue.set(state, 'GAeventName', GAeventName);
		},
		autoId(state, {autoId}) {
			Vue.set(state, 'autoId', autoId);
		},
		args(state, {args}) {
			Vue.set(state, 'args', args);
		},
		abroadData(state, {abroad, dealer, markId, modelId, autoData}) {
			Vue.set(state, 'abroadData', {abroad: abroad, company: dealer, brandId: markId, modelId: modelId, custom: autoData.custom});
		},
		position(state, {position}) {
			Vue.set(state, 'position', position);
		},
		segment(state, {segment}) {
			Vue.set(state, 'segment', segment);
		},
		typeStep(state, {typeStep}) {
			Vue.set(state, 'typeStep', typeStep);
		}
	},
	getters: {
		autoInfo({autoData}) {
			return autoData;
		},
		autotestInfo({autotestData}) {
			return autotestData;
		},
		action({action}) {
			return action;
		},
		GAeventName({GAeventName}) {
			return GAeventName;
		},
		autoId({autoId}) {
			return autoId;
		},
		args({args}) {
			return args;
		},
		abroadData({abroadData}) {
			return abroadData;
		},
		position({position}) {
			return position;
		},
		segment({segment}) {
			return segment;
		},
		typeStep({typeStep}) {
			return typeStep;
		}
	}
};
