<template>
    <div ref="googlePay" />
</template>
<script>
import { mapGetters } from "vuex";

import _laxios from '../../../../store/_laxios';
export default {
  name: "GooglePay",
  props: {
    actionData: {
      type: Object,
      default: null,
    },
    blockData: {
      type: Object,
      default: null,
    },
    onSuccessPaymentGPay: {
      type: Function,
      default: () => 1,
    },
  },
  data: () => ({}),
  watch: {},
  mounted() {
    setTimeout(() => this.loadGooglePay(), 2000);
  },
  computed: {
    ...mapGetters({
      isDesktop: 'shared/device/isDesktop',
    }),
  },
  methods: {
    loadGooglePay() {
      const baseCardPaymentMethod = () => {
        return {
          parameters: {
            // allowedCardAuthMethods потрібно переіменувати
            allowedAuthMethods: this.actionData?.data?.baseCardPaymentMethod?.parameters?.allowedCardAuthMethods,
            allowedCardNetworks: this.actionData?.data?.baseCardPaymentMethod?.parameters?.allowedCardNetworks,
          },
          type: this.actionData?.data?.baseCardPaymentMethod?.type,
        }
      }
      const cardPaymentMethod = () => Object.assign({}, baseCardPaymentMethod(), {
        tokenizationSpecification: this.actionData?.data?.tokenizationSpecification,
      });
      let paymentsClient = null;
      const getGoogleIsReadyToPayRequest = () => {
        return Object.assign({}, this.actionData?.data?.baseRequest, {
          allowedPaymentMethods: [baseCardPaymentMethod()],
        });
      };
      const getGooglePaymentDataRequest = () => {
        const paymentDataRequest = Object.assign({}, this.actionData?.data?.baseRequest);
        paymentDataRequest.allowedPaymentMethods = [cardPaymentMethod()];
        paymentDataRequest.transactionInfo = getGoogleTransactionInfo();
        paymentDataRequest.merchantInfo = this.actionData?.data?.merchantInfo;
        return paymentDataRequest;
      };

      const mode = process.env.NODE_ENV;
      const isProduction = mode === 'production';
      function getGooglePaymentsClient() {
        if (paymentsClient === null) {
          paymentsClient = new google.payments.api.PaymentsClient({
            // Alterar o environment para 'PRODUCTION' em prod
            environment: isProduction ? 'PRODUCTION' : 'TEST',
          });
        }
        return paymentsClient;
      }
      function onGooglePayLoaded() {
        const paymentsClient = getGooglePaymentsClient();
        paymentsClient
            .isReadyToPay(getGoogleIsReadyToPayRequest())
            .then(function (response) {
              if (response.result) {
                addGooglePayButton();
              }
            })
            .catch(function (err) {
              console.error(err);
            });
      }
      const addGooglePayButton = () => {
        const paymentsClient = getGooglePaymentsClient();
        const button = paymentsClient.createButton({
          buttonColor: 'default',
          buttonType: 'buy',
          buttonLocale: 'uk',
          buttonSizeMode: 'fill',
          buttonRadius: 5,
          onClick: onGooglePaymentButtonClicked,
        });
        this.$refs.googlePay.appendChild(button);
      };

      const getGoogleTransactionInfo = () => ({
        ...this.actionData?.data?.transactionInfo,
        totalPrice: this.actionData?.data?.transactionInfo?.totalPrice.toString()
      });

      const onGooglePaymentButtonClicked = () => {
        const paymentDataRequest = getGooglePaymentDataRequest();
        paymentDataRequest.transactionInfo = getGoogleTransactionInfo();
        const paymentsClient = getGooglePaymentsClient();
        paymentsClient
            .loadPaymentData(paymentDataRequest)
            .then((paymentData) => {
              // handle the response
              const paymentToken = processPayment(paymentData);
              if (paymentToken) {
                const data = {
                  services: this.actionData?.services,
                  paymentMethod: 'GPay',
                  paymentData: paymentToken,
                  paymentTypeId: this.actionData?.paymentType,
                  paymentCost: this.actionData?.sum,
                };
                return _laxios.makeOrder
                  .request({ data })
                  .then((response) => {
                    if(response?.payData?.completeData?.redirect_url) {
                      this.onSuccessPaymentGPay(response?.orderId, this.blockData);
                      if (this.isDesktop) {
                        const billingWindow = window.open('https://auto.ria.com/demo/partials/loader/wait.html', '_blank', 'fullscreen=no,height=768,width=1024,toolbar=no,scrollbars=yes');
                        billingWindow.location.href = response?.payData?.completeData?.redirect_url;
                      } else {
                        window.location = response?.payData?.completeData?.redirect_url;
                      }
                    }
                    return response;
                  })
                  .catch((e) => {
                      console.error('makeOrder', e.message);
                    }
                  );
              }
            })
            .catch(function (err) {
              // show error in developer console for debugging
              console.error(err);
            });
      }

      function processPayment(paymentData) {
        const paymentToken = paymentData?.paymentMethodData?.tokenizationData?.token;
        return Buffer.from(paymentToken).toString('base64');
      }

      onGooglePayLoaded();
    },
  },
};
</script>
