export default {
	props: ['data'],
	mixins: [require('../../../mixins/templateEngine')],
	computed: {
		autoData() {
			return this.data && this.data.data;
		}
	},
	i18n: {
		messages: {
			uk: {
				'Публікація вашого оголошення': 'Публікація вашого оголошення',
			},
			ru: {
				'Публікація вашого оголошення': 'Публикация вашего объявления',
			}
		}
	}
};
