import { mapGetters } from 'vuex';

const { _levelsOpenStat } = require('../../../store/_laxios');

export default {
  props: ['data', 'blockReload', 'publicationData', 'onSuccessPaymentGPay'],
  mixins: [require('../../../mixins/templateEngine')],
  components: {
    'pseudo-select': require('./PseudoSelect/index.vue').default,
    GooglePay: () => import('./GooglePay/index.vue'),
  },
  data() {
    return {
      loadBlock: '',
      blockEasyPeriod: true,
    };
  },
  computed: {
    ...mapGetters({
      isWebView: 'shared/device/isWebView',
      userData: 'Common/userData'
    }),
  },
  methods: {
    submitButton({ blockData, button }) {
      this.$emit('onSubmit', { blockData, button });
    },
    slonikEventElevator(eventId, payload) {
      this.$emit('elevateSlonEvent', eventId, payload);
    },
    isBlockEasyPeriod(value) {
      this.blockEasyPeriod = value;
    },
    toEdit() {
      const id = this.$route.params.autoId;
      location.href = `${this.langPrefix}/addauto/autoinfo/autoId/${id}/#VIN-block`;
      const params = {
        auto_id: id,
        src: 'pub_free_add_vin',
      };
      _levelsOpenStat.request({ params }).catch(console.error);
    },
  },
  watch: {
    // спинер на блоках(turbo) при перерисовке блока
    blockReload() {
      this.loadBlock = this.blockReload;
    },
  },
  mounted() {
    if (!window.googlePayScriptLoaded) {
      const googlePayScript = document.createElement('script');
      googlePayScript.src = 'https://pay.google.com/gp/p/js/pay.js';
      googlePayScript.async = true;
      googlePayScript.onload = () => {
        window.googlePayScriptLoaded = true;
      };
      googlePayScript.onerror = (error) => {
        console.error('Error loading Google Pay script:', error);
      };
      document.head.appendChild(googlePayScript);
    }
  },
};
